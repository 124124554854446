import React, { Component } from 'react';
import { Container, Button, Form, Modal, Card, Image } from 'react-bootstrap'
import { ScrollView } from 'react-native-web'
import { FaThumbsUp, FaThumbsDown, FaRegThumbsDown, FaRegThumbsUp } from "react-icons/fa";
import { from } from 'rxjs';
import "./Explore.css"
import moment from 'moment'
import firebase from 'firebase'
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState,convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
class Explore extends Component {
  db = firebase.firestore()
  FieldValue = firebase.firestore.FieldValue

  constructor(props) {
    super(props)

    this.state = {
      newPostText: "",
      posts: [],
      postsReacted: new Object(),
      users: [],
      scrollContentHeight: 0,
      elementHeight: 0,
      lastPageData: null,
      isFetching: false,
      isDeleteModalHide:true,
      deletePostId:null,
      editorState: EditorState.createEmpty()
    };
  }
  componentDidMount() {
    this.setState({isFetching:true},()=>{
      this.getData()
    })
    toast.configure({
      autoClose: 5000,
      draggable: false,
      //etc you get the idea
    });
    window.addEventListener('scroll', this.handleScroll)
  }

  handleScroll = (event) => {


    if ((
      window.innerHeight + document.documentElement.scrollTop
      >= (document.documentElement.offsetHeight - 250)
    ) && this.state.isFetching === false) {
      console.log("load")
      this.setState({isFetching:true},()=>{
        this.getData()
      })
    }
  }


  onCreateNewPost = () => {
    var profileRef = this.db.collection('profile')
    var query = this.db.doc('post')
    let profile = JSON.parse(localStorage.getItem("profile"))
    if (profile !== null && profile.countryCode !== undefined && profile.countryCode != null) {
      let observer = query.where('country', '==', profile.countryCode)
      .onSnapshot(querySnapshot => {
        querySnapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            console.log('New city: ', change.doc.data());
          }
          // if (change.type === 'modified') {
          //   console.log('Modified city: ', change.doc.data());
          // }
          // if (change.type === 'removed') {
          //   console.log('Removed city: ', change.doc.data());
          // }
        });
      });
    }

  }

  getData = () => {
    console.log(this.props)
    const self = this
    var profileRef = this.db.collection('profile')
    var query = null
    let profile = JSON.parse(localStorage.getItem("profile"))
    console.log("kooooo1",null)
    /*if (profile !== null && profile.countryCode !== undefined && profile.countryCode != null) {
      
      if (this.state.lastPageData == null) {
        
        query = this.db.collection('post')
          .where('country', '==', profile.countryCode)
          .orderBy('created', 'desc')
          .limit(20);
      } else {
        
        query = this.db.collection('post')
          .where('country', '==', profile.countryCode)
          .orderBy('created', 'desc').startAfter(this.state.lastPageData.data().created)
          .limit(20);
      }
    } else {*/
      if (this.state.lastPageData == null) {
        
        query = this.db.collection('post')
          .orderBy('created', 'desc')
          .limit(20);
      }else{
        console.log(this.state.lastPageData.data())
        query = this.db.collection('post')
          .orderBy('created', 'desc').startAfter(this.state.lastPageData.data().created)
          .limit(20);
      }
    // }

   
    
    query.get()
      .then((snapshot) => {
        console.log("kooooo2",snapshot)
        self.setState({isFetching:false})
        if (snapshot.docs.length > 0) {
          self.setState({ lastPageData: snapshot.docs[snapshot.docs.length - 1] })
        }

        snapshot.forEach((data) => {
          console.log(data.id)
          self.getReact(data.id)
          var post = data.data()
          post.id = data.id
          if (!self.state.users[post.uid]) {
            profileRef.doc(post.uid).get().then((userSnapshot) => {
              if (userSnapshot.exists) {
                post.user = userSnapshot.data()
                self.setState({ posts: [...self.state.posts, post] })
              }
            })
          } else {
            console.log("ok")
          }
        })

      }).catch((error)=>{
        self.setState({isFetching:false})
      })

  }

  getReact=(postId)=>{
    const self = this
    var user = JSON.parse(localStorage.getItem('user'));
     if (user !== undefined && user !== null) {
      let ref = this.db.collection('post_react').where('postId', '==', postId).where("uid",'==',user.uid)
      ref.get().then((snapshot)=>{
        if(!snapshot.empty){
          if(snapshot.docs[0].exists){
            // 
            console.log('Added document with ID: ', ref.id);
            var reacted = self.state.postsReacted
            console.log(reacted)
            reacted[postId] = {
              uid:user.uid,
              react:snapshot.docs[0].data().react,
              postId:postId,
              id:snapshot.docs[0].id
            }
  
            self.setState({postsReacted:reacted})
            
          }
          
        }
      })
  }



  }

  postPost = () => {
    const self = this
    var user = JSON.parse(localStorage.getItem('user'));
    if (user !== undefined && user !== null) {

      let profile = JSON.parse(localStorage.getItem("profile"))

      if (profile.countryCode === undefined || profile.countryCode === null) {

        this.props.getUserProfile(user.uid, user)

      } else {

        console.log(`${this.state.newPostText}`)
        let data = {
          type: 'text',
          text: `${this.state.newPostText}`,
          created: this.FieldValue.serverTimestamp(),
          uid: user.uid,
          country: profile.countryCode
        };
        // Add a new document in collection "cities" with ID 'LA'
        let setDoc = this.db.collection('post').add(data).then(ref => {
          var post = data
          post.id = ref.id
          post.user = profile
          post.created = firebase.firestore.Timestamp.now()
          self.setState({ newPostText: "", posts: [post, ...self.state.posts] })
        }).catch(error => {

        })
      }
    } else {
      this.props.showLogin()
    }

  }
  onChangePostText = (value) => {
    console.log(draftToHtml(convertToRaw(value.getCurrentContent())))
    this.setState({ newPostText: draftToHtml(convertToRaw(value.getCurrentContent())) ,editorState:value})
    var user = JSON.parse(localStorage.getItem('user'));

    if (user === undefined || user === null) {
      this.props.showLogin()
    }
  }



  deletePost=()=>{
       if(this.state.deletePostId !== null){
         const self = this
        let deleteRef = this.db.collection('post').doc(this.state.deletePostId)
        deleteRef.delete().then((ref)=>{
          self.setState({isDeleteModalHide:true},()=>{
            
            toast.success("Post delete successfully !", {
              position: toast.POSITION.TOP_CENTER
            });
            const items = self.state.posts.filter(item => item.id !== self.state.deletePostId);
            this.setState({ posts: items,deletePostId:null });
          })
        }).catch((error)=>{
             console.log(error)
             
             toast.error("ESorry post can't delete successfully !!!", {
              position: toast.POSITION.TOP_LEFT
            });
        })
       }
  }

  addReact=(postId,action,post)=>{
// like or unlike 
const self = this
     let ref = this.db.collection('post_react')
     var user = JSON.parse(localStorage.getItem('user'));
     if (user !== undefined && user !== null) {
     ref.where('uid', '==', user.uid).where('postId',"==",postId).get()
     .then(snapshot => {
       if (snapshot.empty) {
        ref.add({
          uid:user.uid,
          react:action,
          postId:postId
        }).then(ref => {
          console.log('Added document with ID: ', ref.id);
          var reacted = self.state.postsReacted
          console.log(reacted)
          reacted[postId] = {
            uid:user.uid,
            react:action,
            postId:postId,
            id:ref.id
          }
          let index = self.state.posts.indexOf(post)
          if(action === "Like"){
            if(self.state.posts[index].likes_count === undefined || self.state.posts[index].likes_count === null){
              self.state.posts[index].likes_count = 0
            }
            
          self.state.posts[index].likes_count = self.state.posts[index].likes_count + 1
        }else if(action === "Dislike"){
          if(self.state.posts[index].dislikes_count === undefined || self.state.posts[index].dislikes_count === null){
            self.state.posts[index].dislikes_count = 0
          }
          self.state.posts[index].dislikes_count = self.state.posts[index].dislikes_count + 1
        }
          self.setState({postsReacted:reacted})
        }).catch((error)=>{
          console.log(error)
        });
         return;
       }else{
            let postReact = snapshot.docs[0]
            postReact.ref.update({react:action}).then(ref=>{
              var reacted = self.state.postsReacted

              let index = self.state.posts.indexOf(post)
              if(reacted[postId].react === "Like"){
                if(self.state.posts[index].likes_count === undefined || self.state.posts[index].likes_count === null){
                  self.state.posts[index].likes_count = 0
                }
                 self.state.posts[index].likes_count = self.state.posts[index].likes_count - 1
                 if(self.state.posts[index].likes_count < 0){
                  self.state.posts[index].likes_count = 0
                }
              }else if(reacted[postId].react === "Dislike"){
                if(self.state.posts[index].dislikes_count === undefined || self.state.posts[index].dislikes_count === null){
                  self.state.posts[index].dislikes_count = 0
                }
                self.state.posts[index].dislikes_count = self.state.posts[index].dislikes_count - 1
                if(self.state.posts[index].dislikes_count < 0){
                  self.state.posts[index].dislikes_count = 0
                }
              }
          reacted[postId] = {
            uid:user.uid,
            react:action,
            postId:postId,
            id:postReact.id
          }
          if(action === "Like"){
            
            self.state.posts[index].likes_count = self.state.posts[index].likes_count + 1
          }else if(action === "Dislike"){
            self.state.posts[index].dislikes_count = self.state.posts[index].dislikes_count + 1
          }
          console.log("reacted[postId]",reacted[postId])

          self.setState({postsReacted:reacted})
              console.log("done")
            }).catch(error=>{
              console.log(error)
            })
       }  
       
     })
     .catch(err => {
       console.log('Error getting documents', err);
     });
    }else{
      this.props.showLogin()
    }
  }

  getPostView = (post, index) => {
    var doc = null
    var html_body = null
    try{
      doc = null
      html_body = {
        __html: `${post.text}`
      };
  }catch(error){
    doc = post.text
  }
    return (
      <Card key={index} style={{ marginBottom: 32 }}>
        <Card.Header>
          <Image src={post.user.photoURL} style={{ width: 32, marginRight: 8 }} roundedCircle />{post.user.displayName}
        {(this.props.user !== null && this.props.user.uid === post.uid) ? <Button style={{float:'right'}} variant="danger"
        onClick={()=>{
          this.setState({deletePostId:post.id},()=>{this.setState({isDeleteModalHide:false})})
        }}>Delete</Button> : null}
        </Card.Header>
        <Card.Body style={{whiteSpace:'pre-wrap'}}>
          {/* {doc} */}

          {html_body ? <span dangerouslySetInnerHTML={html_body} /> : post.text}
 
        </Card.Body>
        <Card.Body>
    <Card.Link onClick={()=>{
      console.log("like")
      this.addReact(post.id,"Like",post)
    }}>{post.likes_count ? post.likes_count : 0}
    { (this.state.postsReacted[post.id] !== undefined && this.state.postsReacted[post.id] !== null && this.state.postsReacted[post.id].react === "Like") ?
      <FaThumbsUp style={{verticalAlign:"baseline",marginLeft:4}}/> :
      <FaRegThumbsUp style={{verticalAlign:"baseline",marginLeft:4}}/>
    }
    </Card.Link>
    <Card.Link  onClick={()=>{
      console.log("unlike")
      this.addReact(post.id,"Dislike",post)
    }}>{post.dislikes_count ? post.dislikes_count : 0}
    { (this.state.postsReacted[post.id] !== undefined && this.state.postsReacted[post.id] !== null && this.state.postsReacted[post.id].react === "Dislike") ?
      <FaThumbsDown  style={{verticalAlign:"middle",marginLeft:4}}/> :
      <FaRegThumbsDown style={{verticalAlign:"middle",marginLeft:4}}/>
    
    }
    
    </Card.Link>
  </Card.Body>
        <Card.Footer>
          <small className="text-muted">Last updated {moment(post.created.toDate()).fromNow()}</small>
        </Card.Footer>
      </Card>
    )
  }

  validatePost=()=>{
    let value = this.state.newPostText.trim()
    if(value.length !== 0){
      this.postPost()
    }else{

    }
  }

  updateContent(value) {
    this.setState({content:value})
}

  render() {
    this.config = {
      uploader:{
      insertImageAsBase64URI:true
    }
     }

    return (
      <div>
        <div className="main-div">
        {/* <ToastContainer /> */}
        <Editor
        editorStyle={{border:"1px solid gainsboro"}}
  editorState={this.state.editorState}
  toolbarClassName="toolbarClassName"
  wrapperClassName="wrapperClassName"
  editorClassName="editorClassName"
  onEditorStateChange={(event) => { this.onChangePostText(event) }}
  toolbar={{
    options: ['inline', 'blockType', 'fontSize','list', 'textAlign', 'colorPicker', 'link', 'embedded', 'image', 'remove', 'history'],
    inline: { inDropdown: true },
    list: { inDropdown: true },
    textAlign: { inDropdown: true },
    link: { inDropdown: true },
    image: {
      uploadEnabled: true,
      uploadCallback: undefined,
      defaultSize: {
        height: '100%',
        width: '100%',
      }, 
    },
    embedded: {
      defaultSize: {
        height: '300',
        width: '100%',
      },
    }
  }}
/>
          <div style={{ marginBottom: 54 }}>
            {/* <Form.Control as="textarea"
              value={this.state.newPostText}
              rows="4" placeholder="let your voice to be heard..." onChange={(event) => { this.onChangePostText(event.target.value) }} /> */}
            <div style={{ marginTop: 4 }}>
              <Button variant="dark" size="sm" style={{ float: "right" }} onClick={() => {
                this.validatePost()
              }}>Post</Button>
            </div>
          </div>
          <ScrollView id="container" onLayout={(evt) => {
            this.setState({ scrollContentHeight: evt.nativeEvent.layout.height })
          }}>
            {this.state.posts.map((value, index) => {
              return this.getPostView(value, index)
            })}

          </ScrollView>
        </div>
        <Modal show={!this.state.isDeleteModalHide} onHide={()=>{
           this.setState({isDeleteModalHide:true})
        }} >
        <Modal.Header closeButton>
          <Modal.Title>Select Your Contry</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        Are you sure do you want to delete this post
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={()=>{
            this.setState({isDeleteModalHide:true})
          }}>
            Close
          </Button>
          <Button variant="danger" onClick={()=>{
            this.deletePost()
          }} >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer autoClose={5000} />
      </div>
    );
  }
}


export default Explore;