import React, { Component } from 'react';

class Contact extends Component {
  render() {
    return (
        <div style={{margin:16}}>
          <h2>Contact</h2>
          <p>If you have any concern feel free to contact:</p>
          <b>info.evoice360@gmail.com</b>
        </div>
    );
  }
}

export default Contact;