import React, { Component } from 'react';
import { Container, Button, Card, ProgressBar, Image, Modal,Alert} from 'react-bootstrap'

import "./Home.css"
import firebase from 'firebase'
class Home extends Component {
  db = firebase.firestore()
  constructor(props) {
    super(props)
    this.state = {
      candidates: [],
      isVoteButtonDisable: false,
      votedCandidateId: null,
      showVoteChangeModal: false,
      newUpdateVotedCandidateId: null,
      totalNumOfVotes: 0,
      keyValuesCandidates: []
    }
  }
  componentDidMount() {



    this.db.collection('votes').doc('president_election_vote').collection('2019').onSnapshot((snapshot) => {
      snapshot.forEach((doc) => {
        
        if (this.state.candidates.length === 0 || this.state.candidates[doc.id] === 'undefined' || this.candidates[doc.id] === null) {
          var candidates = this.state.candidates
          candidates[doc.id] = doc.data()
          this.setState({ candidates: candidates })
        }

      });
      this.getTotalVotesCount()
    }, (error) => {
      console.log('Error getting documents', error);
      //...
    });
    this.checkIsAlreadyVoted()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user !== this.props.user) {
      if (this.props.user !== 'undefined' && this.props.user !== null) {
        this.checkIsAlreadyVoted()
      }
    }
  }

  sortCandidates(candidates) {
    var keyValues = []

    for (var key in candidates) {
      keyValues.push([key, candidates[key]])
    }

    var sorted = keyValues.sort(function compare(kv1, kv2) {
      // This comparison function has 3 return cases:
      // - Negative number: kv1 should be placed BEFORE kv2
      // - Positive number: kv1 should be placed AFTER kv2
      // - Zero: they are equal, any order is ok between these 2 items
      var kv1NumVotes = kv1[1].numVotes
      if (kv1NumVotes === "undefined" || kv1NumVotes === null) {
        kv1NumVotes = 0
      }
      var kv2NumVotes = kv2[1].numVotes
      if (kv2NumVotes === "undefined" || kv2NumVotes === null) {
        kv2NumVotes = 0
      }
      return kv2NumVotes - kv1NumVotes
    })
    return sorted
  }

  getTotalVotesCount() {
    var numOfVotes = 0
    Object.keys(this.state.candidates).map((key, index) => (
      numOfVotes = numOfVotes + this.state.candidates[key].numVotes
    ));
    this.setState({ totalNumOfVotes: numOfVotes })
  }

  getUserId(user) {
    if (user != null) {
      if (user.uid != null) {
        return user.uid
      }
    }
    return null
  }

  getPrecentage(numVotes, totalNumOfVotes) {

    return Number(((numVotes / totalNumOfVotes) * 100).toFixed(1))
  }

  getCurrentVotedCandidateName() {
    if (this.state.votedCandidateId !== "undefined" && this.state.votedCandidateId !== null) {
      return this.state.candidates[this.state.votedCandidateId].candidate_name
    }

    return ""
  }
  getNewUpdateVotedCandidateName() {

    if (this.state.newUpdateVotedCandidateId !== "undefined" && this.state.newUpdateVotedCandidateId !== null) {
      return this.state.candidates[this.state.newUpdateVotedCandidateId].candidate_name
    }

    return ""
  }
  checkIsAlreadyVoted() {

    let userId = this.getUserId(this.props.user)
    if (userId == null) {

      return
    }
    const ref = this.db.collection('votes').doc('president_election_vote').collection("2019_vote_count")
    ref.where('user_id', '==', userId).onSnapshot((snapshot) => {
      if (snapshot.empty) {

        return;
      } else {
        snapshot.forEach(doc => {
          
          this.setState({ votedCandidateId: doc.data().candidate_id })
        });
      }
    }, (error) => {
      console.log('Error getting documents', error);
    });

    return null
  }

  setVote(candidate_key) {
    if (!this.props.isSignedIn) {
      this.props.showLogin()
      //this.props.showLogin(this.props.showLogin)

    } else {
      let userId = this.getUserId(this.props.user)
      if (userId != null) {
        this.setState({ isVoteButtonDisable: true })
        const ref = this.db.collection('votes').doc('president_election_vote').collection("2019_vote_count")
        ref.where('user_id', '==', userId).get().then(snapshot => {
          this.setState({ isVoteButtonDisable: false })
          if (snapshot.empty) {
            let data = {
              user_id: userId,
              candidate_id: candidate_key
            }
            ref.add(data)

            return;
          } else {
            this.setState({ newUpdateVotedCandidateId: candidate_key }, () => {
              this.setState({ showVoteChangeModal: true })
            })

          }
        })
          .catch(err => {
            console.log('Error getting documents', err);
            this.setState({ isVoteButtonDisable: false })
          });
      } else {
        this.props.showLogin()
      }
    }
    //
  }

  updateVote() {
    let userId = this.getUserId(this.props.user)
    if (userId == null) {

      return
    }

    const ref = this.db.collection('votes').doc('president_election_vote').collection("2019_vote_count")
    ref.where('user_id', '==', userId).get().then(snapshot => {
      if (snapshot.empty) {

        return;
      } else {
        snapshot.forEach(doc => {
          let newRef = ref.doc(doc.id)
          newRef.update({ candidate_id: this.state.newUpdateVotedCandidateId })
        });
        this.setState({ showVoteChangeModal: false })
      }
    })
      .catch(err => {
        console.log('Error getting documents', err);
      });
  }

  render() {

    this.cellRender = (key) => {
      var data = this.state.candidates[key]
      return (
        <div className="column" style={{ margin: 10 }} key={key}>
          <Card className="card-width">
            <Card.Img variant="top" src={data.cover_img_url} style={{ maxHeight: 200 }} />
            <Card.ImgOverlay style={{ bottom: 'unset' }}><Image
              src={data.logo_url} rounded style={{ width: 32 }} /></Card.ImgOverlay>
            <Card.Body>

              <Card.Title>{data.candidate_name}</Card.Title>
              <Card.Text>{data.political_party}</Card.Text>
               
             <div style={{ paddingBottom: 16}}>
                <div>Votes:{data.numVotes ? data.numVotes : 0}</div>
                <style type="text/css">
                  {`.progress-custom-${key} {
    color: white;

  }
  .progress-custom-${key} .progress-bar{
    background-color: ${data.color};
  }`}
                </style>

                <ProgressBar variant={"progress-custom-" + key} animated now={this.getPrecentage(data.numVotes ? data.numVotes : 0, this.state.totalNumOfVotes)} label={this.getPrecentage(data.numVotes ? data.numVotes : 0, this.state.totalNumOfVotes) + "%"} className={"progress-custom-" + key} />
              </div>

              {/* {if(this.state.votedCandidateId === key) ? <Button>:null} */}
              {(this.state.votedCandidateId === key) ? <Button variant="secondary" disabled={true}>Vote</Button> : <Button variant={this.state.isVoteButtonDisable ? "secondary" : "primary"} onClick={this.setVote.bind(this, key)} disabled={this.state.isVoteButtonDisable}>Vote</Button>}

            </Card.Body>
          </Card>
        </div>
      )
    }
    return (
      <Container>
        <Alert variant={"info"}>
        This is only a small sample of social media friends. Hence this voting result cannot be campared with real presidential election result and we only publish this because election is over.
        </Alert>
        <Alert variant={"danger"}>
            total votes count is: {this.state.totalNumOfVotes}
        </Alert>
        <div className="row" style={{ justifyContent: 'center' }}>
          {/* {Object.keys(this.state.candidates).map((data, index) => (
                        this.cellRender(data)
                    ))
                    } */}
          {this.sortCandidates(this.state.candidates).map((data) => (
           
            this.cellRender(data[0])
           
          ))}
        </div>
        {/* Modal */}
        <Modal show={this.state.showVoteChangeModal} onHide={() => { this.setState({ showVoteChangeModal: false }) }}>
          <Modal.Header closeButton>
            <Modal.Title>Change your Vote</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want to change your vote <b>{this.getCurrentVotedCandidateName()}</b> to <b>{this.getNewUpdateVotedCandidateName()}</b></Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => { this.setState({ showVoteChangeModal: false }) }}>
              Close
          </Button>
            <Button variant="primary" onClick={() => {
              this.updateVote()
            }}>
              Yes, Vote
          </Button>
          </Modal.Footer>
        </Modal>
        {/* end modal */}
      </Container>
    );
  }
}

export default Home;