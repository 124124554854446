import React, { Component } from 'react';
import styles from './MainSideBar.css';
import {Nav, NavItem, Navbar, NavDropdown} from 'react-bootstrap';
import {Link } from 'react-router-dom';
class MainNavBar extends Component {
    render() {
        return (
        <div id="mySidebar" className="sidebar">
  <Link to="/">Explore</Link>
  <Link to="/about">About</Link>
  <Link to="/contact">Contact</Link>
  {/* <Link to="/vote">2020 SL Vote</Link> */}
</div>
        
            );
    }
}
export default MainNavBar;


{/* 
    <Navbar fluid className={styles.sidebar} inverse >

<Navbar.Header>
    <Navbar.Brand>
        <a href="/">User Name</a>
    </Navbar.Brand>
    <Navbar.Toggle />
</Navbar.Header>

<Navbar.Collapse>
    <Navbar.Text className={styles.userMenu}>
        <Navbar.Link href="#">home</Navbar.Link>
        <Navbar.Link href="#">log-out</Navbar.Link>
    </Navbar.Text>
    <Nav>
        <NavDropdown eventKey={1} title="Item 1"> 
    */}
            {/* <MenuItem eventKey={1.1} href="#">Item 1.1</MenuItem> */}
//         </NavDropdown>
//         <NavItem eventKey={2}>Item 2</NavItem>
//         <NavItem eventKey={3}>Item 3</NavItem>
//     </Nav>
// </Navbar.Collapse>

// </Navbar>