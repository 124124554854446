import React, { Component } from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'
import Home from './views/Home/'
import About from './views/About';
import Contact from './views/Contact';
import Explore from './views/Explore/';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainNavBar from './Components/NavigationBars/MainNavBar';
import MainSideBar from './Components/NavigationBars/MainSideBar';
import LoginModal from './Components/Modal/LoginModal/';
import axios from 'axios'
import image2base64 from 'image-to-base64';
import firebase from 'firebase/app';
import "firebase/auth";
import CountryDropDown from "./Components/DropDowns/CountryDropDown/"

import Loading from 'react-fullscreen-loading';

// import undefined from 'firebase/auth';
// import country from 'countryjs'


var firebaseConfig = {
  apiKey: "AIzaSyD4sL0mzBNAxb1F464a6RUAkXDupUpBwyY",
  authDomain: "evoice360.firebaseapp.com",
  databaseURL: "https://evoice360.firebaseio.com",
  projectId: "evoice360",
  storageBucket: "evoice360.appspot.com",
  messagingSenderId: "797994316939",
  appId: "1:797994316939:web:dcb3991cc1e3fe12a5d08e",
  measurementId: "G-HJR5WVTZKT"
};

firebase.initializeApp(firebaseConfig);
class App extends Component {

  db = firebase.firestore()
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      isSignedIn: false,
      token: null,
      user: null,
      showErrorMessageModal: false,
      errorMessage: "",
      showCountryDropDownModal:false,
      country: null,
      countryCode:null,
      isLoadingAuth:false
    }


  }

  fetchLocation=()=>{
    const self = this
    axios.get(`https://freegeoip.app/json/`)
    .then(res => {
      
      if(res.data !== undefined && res.data !== null){
        
          if(res.data.country_code !== undefined && res.data.country_code !== null){
            
            self.setState({countryCode:res.data.country_code})
          }
      }
      // const persons = res.data;
      // this.setState({ persons });
    })
  }

  login = () => {
    const self = this

    firebase.auth().getRedirectResult().then(function (result) {
      // This gives you a Google Access Token. You can use it to access the Google API.
      if (result.user != null) {
        var token = result.credential.accessToken;

        // The signed-in user info.
        var user = result.user;
        
        self.getUserProfile(user.uid,user);

        self.setSignedIn(true)
        self.setToken(token)
        self.setUser(user)
        self.setModalShow(false)

        
        localStorage.setItem('token', token);
       
      }

      // ...
    }).catch(function (error) {
      
      // Handle Errors here.
      var errorCode = error.code;
      var errorMessage = error.message;
      // The email of the user's account used.
      var email = error.email;
      // The firebase.auth.AuthCredential type that was used.

      if (email !== "undefined" && email != null) {
        errorMessage = email + ", " + errorMessage
      }
      self.setModalShow(false)
      var credential = error.credential;
      self.setSignedIn(false)
      self.setToken(null)
      self.setUser(null)
      firebase.auth().signOut()
      self.setState({ showErrorMessageModal: true, errorMessage: errorMessage })
      localStorage.clear();
      // ...
    });
  }

  getUserProfile = (userId,user) => {

    localStorage.setItem('user', JSON.stringify(user));
    
    localStorage.setItem('uid', userId);
    const self = this
    this.db.collection("profile").doc(userId).onSnapshot((snapshot) => {
      if(snapshot.exists){
        let profile = snapshot.data()
        localStorage.setItem("profile",JSON.stringify(profile))
        if(profile.countryCode === undefined){
          self.fetchLocation()
          this.setState({showCountryDropDownModal:true})
        }
        if(profile.displayName === undefined){
          self.setProfileDisplayName(userId,user.displayName)
        }
        if(profile.photoURL === undefined){
          self.setProfilePhotoURL(userId,user.photoURL)
        }

      }else{
        
        let profile = JSON.parse(localStorage.getItem("profile"))
        console.log('profile',profile)
        self.fetchLocation()
        if(profile == null || profile.countryCode === undefined){
          self.fetchLocation()
          this.setState({showCountryDropDownModal:true})
        }
        if(profile == null || profile.displayName === undefined){
          self.setProfileDisplayName(userId,user.displayName)
        }
        if(profile == null || profile.photoURL === undefined){
          self.setProfilePhotoURL(userId,user.photoURL)
        }
      }
      // snapshot.forEach((doc) => {
      //   console.log(doc.id, '=>', doc.data());

      // });
    }, (error) => {
      console.log('Error getting documents', error);
      //...
    });
  }

  setProfileDisplayName=(userId,displayName)=>{
    let profileRef = this.db.collection('profile').doc(userId);
    profileRef.set({displayName: displayName},{merge: true})
  }
  setProfilePhotoURL=(userId,photoURL)=>{
    var storageRef = firebase.storage().ref();
    var profileImageRef = storageRef.child('users/profile_img/');
    let profileRef = this.db.collection('profile').doc(userId);
    image2base64(photoURL) // you can also to use url
    .then(
        (response) => {
          
            profileImageRef.child(userId)
            .putString(response, 'base64', { contentType: 'image/png' })    
  .then((snapshot) => {
        
        snapshot.ref.getDownloadURL().then(function(downloadURL) {
          profileRef.set({photoURL: downloadURL},{merge: true})
        });
        
      })
        }
    )
    .catch(
        (error) => {
            console.log(error); //Exepection error....
        }
    )

    // profileRef.set({photoURL: displayName},{merge: true})
  }

  setSignedIn = (data) => {
    this.setState({ isSignedIn: data })
  }
  setToken = (data) => {
    this.setState({ token: data })
  }
  setUser = (data) => {
    
    if (data != null){
      this.getUserProfile(data.uid,data);
    }
    this.setState({ user: data })
  }
  setModalShow = (data) => {
    this.setState({ modalShow: data })
  }
  handleCloseCountryModal=(isSave=false)=>{
    if(isSave === true){
        if(this.state.countryCode !== null){
          this.setState({showCountryDropDownModal:false,showErrorMessageModal: false})
          this.setProfile(this.state.user.uid)
        }else{
          this.setState({ showErrorMessageModal: true, errorMessage: "Please Select your Country" })
        }
    }else{
      this.setState({showCountryDropDownModal:false})
    }
    
 }
  getProvider = (from) => {
    if (from === "facebook") {
      return new firebase.auth.FacebookAuthProvider();
    } else if (from === "google") {
      return new firebase.auth.GoogleAuthProvider();
    } else if (from === "twitter") {
      return new firebase.auth.TwitterAuthProvider();
    } else if (from === "github") {
      return new firebase.auth.GithubAuthProvider();
    } else if (from === "microsoft") {
      return new firebase.auth.OAuthProvider('microsoft.com');
    }
  }

  setProfile=(userId)=>{
    let profileRef = this.db.collection('profile').doc(userId);
    profileRef.set({countryCode: this.state.countryCode},{merge: true})
  }


  handleLogin = (from) => {
    const self = this
    var provider = this.getProvider(from);
    // firebase.auth().signInWithRedirect
    firebase.auth().signInWithRedirect(provider);

  }

  logout = () => {
    firebase.auth().signOut()
    localStorage.clear()

  }

  componentDidMount() {
    firebase.analytics().logEvent('notification_received');
    this.setState({isLoadingAuth:true})
    const self = this
    firebase.auth().onAuthStateChanged(user => {
      self.setState({isLoadingAuth:false})
      this.setSignedIn(!!user)
      if (this.state.isSignedIn) {
        this.setSignedIn(true)
        user.getIdToken().then(function (idToken) {

        });
        // this.setToken(token)
        this.setModalShow(false)
        this.setUser(user)
      } else {
        this.setSignedIn(false)
        this.setToken(null)
        this.setUser(null)
      }
    })
  }


  render() {


    return (
      <Router>
        <MainNavBar user={this.state.user} isSignedIn={this.state.isSignedIn} onClickLogin={() => this.setModalShow.bind(this, true)}
          onClickLogout={

            this.logout.bind(this)}

        />
        <MainSideBar />
        <LoginModal show={this.state.modalShow}
          onHide={() => this.setModalShow(false)} onClickLoginAction={(from) => this.handleLogin(from)} />


        <div className="main_content_body">
          <Switch>
            <Route exact path='/vote' render={(props) => <Home user={this.state.user} isSignedIn={this.state.isSignedIn} showLogin={() => this.setModalShow(true)} />} />
            <Route path='/contact' component={Contact} />
            <Route path='/about' component={About} />
            <Route path='/' render={(props) => <Explore showLogin={() => this.setModalShow(true)} getUserProfile={this.getUserProfile} user={this.state.user}/>}/>
          </Switch>
          <Modal show={this.state.showErrorMessageModal} onHide={() => { this.setState({ showErrorMessageModal: false }) }}>
            <Modal.Header closeButton>
              <Modal.Title>Modal heading</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.state.errorMessage}</Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={() => { this.setState({ showErrorMessageModal: false }) }}>
                Dismiss
          </Button>
            </Modal.Footer>
          </Modal>

          {/* select country */}
          <Modal show={this.state.showCountryDropDownModal} onHide={this.handleCloseCountryModal} >
        <Modal.Header closeButton>
          <Modal.Title>Select Your Contry</Modal.Title>
        </Modal.Header>
        <Modal.Body scrollable="true" >
        <CountryDropDown countryCode={this.state.countryCode} selectedCountryCode={(code)=>{
          this.setState({countryCode:code})
        }}/>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleCloseCountryModal.bind(this)}>
            Close
          </Button>
          <Button variant="primary" onClick={this.handleCloseCountryModal.bind(this,true)} >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
          {/* end select country */}
        </div>
        {/* loding */}
        <Loading loading={this.state.isLoadingAuth} background="rgba(0,0,0,0.7)" loaderColor="black" />
 {/* loading */}
      </Router>)
  }
}

export default App;