import React, { Component } from 'react';

import { Button, Modal } from 'react-bootstrap'
import { FacebookLoginButton,GoogleLoginButton,TwitterLoginButton,GithubLoginButton,MicrosoftLoginButton } from "react-social-login-buttons";

class LoginModal extends Component {

    render() {

        return (<Modal onHide={this.props.onHide.bind(this)}
            show={this.props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Login Or Register with
              </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{alignSelf:'center'}}>
                <div style={{width:270,justifyContent:'center'}}>
               <div className="row">     
               <FacebookLoginButton onClick={()=>{
                   this.props.onClickLoginAction('facebook')
               }}/>
               </div>
               <div className="row">  
               <GoogleLoginButton onClick={()=>{
                   this.props.onClickLoginAction('google')
               }}/>
               </div>
               <div className="row">  
               {/* <TwitterLoginButton onClick={()=>{
                   this.props.onClickLoginAction('twitter')
               }}/> */}
               </div>
               <div className="row">  
               <GithubLoginButton onClick={()=>{
                   this.props.onClickLoginAction('github')
               }}/>
               </div>
               <div className="row">  
               {/* <MicrosoftLoginButton onClick={()=>{
                   this.props.onClickLoginAction('microsoft')
               }}/> */}
               </div>
               </div>

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={this.props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>);
    }
}

export default LoginModal;