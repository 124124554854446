import React, { Component } from 'react';

import { Navbar, Image, Button, Nav } from 'react-bootstrap'
import styles from './MainNavBar.css';
class MainNavBar extends Component {

    getUserName(user) {
        if (user != null) {
            if(user.displayName != null){
               return user.displayName
            }
        }
        return ""
    }

    getphotoURL(user) {

        if (user != null) {
            if(user.photoURL != null){
               return user.photoURL
            }
        }
        return ""
    }

    render() {
        return (
            <Navbar expand="lg" variant="dark" bg="dark" style={{ zIndex: 3 }} fixed="top">
                {/* <Navbar.Toggle  aria-controls="basic-navbar-nav1"/> */}
                <Navbar.Brand href="#">evoice360</Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="mr-auto"></Nav>
                    <Nav>
                    <Nav.Link href="/" className="hidden-option">explore</Nav.Link>
      {/* <Nav.Link href="/vote" className="hidden-option" >2020 SL Vote</Nav.Link> */}
      <Nav.Link href="/about" className="hidden-option">About</Nav.Link>
  <Nav.Link href="/contact" className="hidden-option">Contact</Nav.Link>
                        {this.props.isSignedIn ?
                            <div style={{ paddingRight: 16, visibility: this.props.isSignedIn ? 'visible' : 'hidden' }}>
                                <Image style={{ width: 32, height: 32 }} src={this.getphotoURL(this.props.user)}
                                    roundedCircle />
                                <Navbar.Text style={{ paddingLeft: 8 }}>{this.getUserName(this.props.user)}</Navbar.Text>
                            </div>
                            : null
                        }
                        {
                            this.props.isSignedIn ?
                                <Button variant="outline-light" onClick={this.props.onClickLogout.bind(this)}>Logout</Button>
                                :
                                <Button variant="outline-light" onClick={this.props.onClickLogin()}>Login</Button>
                        }

                    </Nav>
                </Navbar.Collapse>

            </Navbar>
        );
    }
}

export default MainNavBar;