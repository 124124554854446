import React, { Component } from 'react';

class About extends Component {
  render() {
    return (
        <div style={{margin:16}}>
          <h2>About</h2>
          <p>Being a professional political account maintainer evoice360 gives you an opportunity to let others know your political voice.

As a fresh start I have set a voting session for selecting Sri Lankan president. In near future you’ll be able to express your political views, likes, dislikes and suggestions in cyber space via evoice360.</p>
        </div>
    );
  }
}

export default About;