import React, { Component } from 'react';
import { Dropdown,FormControl,Button} from 'react-bootstrap'
import {ScrollView} from 'react-native-web'
import axios from 'axios';
import { getCountryCallingCodeAsync } from 'react-native-country-picker-modal/lib/CountryService';

var countries  = require('country-data').countries
var lookup = require('country-data').lookup;

export default function Index(props){
const countryOptions =[]
countries.all.forEach((value)=>{
  
   var country = { key: value.alpha2, value: value.alpha2, flag: value.alpha2, text: value.name,emoji:value.emoji }
   countryOptions.push(country)
})

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
    size="lg"
      href=""
      ref={ref}
      onClick={e => {
        e.preventDefault();
        onClick(e);
      }}
      block>
      {children}
      &#x25bc;
    </Button>
  ));
  const [value, setValue] = React.useState('');
  // forwardRef again here!
  // Dropdown needs access to the DOM of the Menu to measure it
  const CustomMenu = React.forwardRef(
    ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
     
  
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder="Type to filter..."
            onChange={e => setValue(e.target.value)}
            value={value}
          />
           <ScrollView style={{height:300}}>
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              child =>
                !value || child.props.children.toLowerCase().startsWith(value),
            )}
          </ul>
          </ScrollView>
        </div>
      );
    },
  );

  function getCountryByCode(code){
    var countries = lookup.countries({alpha2: code})
    
    if(countries !== null && countries.length > 0){
        
      return countries[0].name
    }
    return null
  }

  return(
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
        {getCountryByCode(props.countryCode)?getCountryByCode(props.countryCode):"Select Your Country"}
      </Dropdown.Toggle>
  
      <Dropdown.Menu as={CustomMenu} >
       
        {
            countryOptions.map((event,index)=>{
               return(
                <Dropdown.Item 
                onSelect={(eventKey,event)=>{
                   props.selectedCountryCode(eventKey)
               }}
                 key={index} eventKey={event.key} style={{borderBottom:"1px solid gainsboro",whiteSpace:"unset"}}>{event.text}</Dropdown.Item>
               );
            })
        }

      </Dropdown.Menu>
    </Dropdown>
  );
}